import type { AppRouteModule } from "/@/router/types";

import { LAYOUT } from "/@/router/constant";
import { t } from "/@/hooks/web/useI18n";

const system: AppRouteModule = {
  path: "/contractInsurance",
  name: "ContractInsurance",
  component: LAYOUT,
  redirect: "/contractInsurance/insurance",
  meta: {
    orderNo: 20,
    roles: ["admin",
      "account_detail",
      "TaxDeduct",
      "Contract",
      "Insurance",
      "Project"
    ],
    // icon: "ant-design:file-done-outlined",
    title: t("routes.menus.contractInsurance.contractInsurance")
  },
  children: [
    {
      path: "insurance",
      name: "Insurance",
      meta: {
        // realPath: "/contractInsurance/insurance",
        title: t("routes.menus.business.insurance"),
        ignoreKeepAlive: false,
        roles: ["admin", "Insurance"]
      },
      component: () => import("/@/views/business/insurance/index.vue")
    },
    {
      path: "insuranceDetail/:id/:name?",
      name: "InsuranceDetail",
      meta: {
        hideMenu: true,
        title: t("routes.menus.business.account_detail"),
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/contractInsurance/insurance",
        roles: ["admin", "Insurance", "account_detail"]
      },
      component: () => import("/@/views/business/insurance/Detail.vue")
    },
    {
      path: "taxDeduct",
      name: "TaxDeduct",
      meta: {
        title: t("routes.menus.business.taxDeduct"),
        roles: ["admin", "TaxDeduct"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/business/taxDeduct/index.vue")
    }
    /*{
      path: "contract",
      name: "Contract",
      meta: {
        title: t("routes.menus.people.contract"),
        ignoreKeepAlive: false,
        roles: ["admin", "Contract"]
      },
      component: () => import("/@/views/people/contract/index.vue")
    },
    {
      path: "contractDetail/:id/:name",
      name: "ContractDetail",
      meta: {
        hideMenu: true,
        title: t("routes.menus.people.contractDetail"),
        roles: ["admin", "Contract"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/contractInsurance/contract"
      },
      component: () => import("/@/views/people/contract/ContractDetail.vue")
    },*/

    //项目执行
    /*, {
      path: "project",
      name: "Project",
      meta: {
        title: t("routes.menus.contractInsurance.project"),
        ignoreKeepAlive: false,
        roles: ["admin", "Project"]
      },
      component: () => import("/@/views/contractInsurance/project/index.vue")
    }*/
  ]
};

export default system;
